.titulo1Home{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 55px;
    color: black;
}

.seccion1-Home{
    margin-top: 80px;
        
}
.sub1Home{
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 25px;
    color: black;
    padding-top: 30px;
}

.btnAgendarHome{
    font-family: 'Poppins';
    font-weight: 600 ;
    font-size: 18px ;
    color: white ;
    background-color: #EA3637 ;    
    border-radius: 50px;
    border: none;
    width: 250px;
    height: 47px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}
.btnAgendarHome:link,
.btnAgendarHome:visited {
	text-transform: uppercase;
	text-decoration: none;
	padding: 15px 40px;
	display: inline-block;
	border-radius: 100px;
	transition: all 0.2s;
	position: absolute;
}

.btnAgendarHome:hover {
	transform: translateY(-3px);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btnAgendarHome:active {
	transform: translateY(-1px);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}



.btnAgendarHome::after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	transition: all 0.4s;
}

.btn-efecto::after {
	background-color: #EA3637;
}

.btnAgendarHome:hover::after {
	transform: scaleX(1.4) scaleY(1.6);
	opacity: 0;
}

.video1Home{    
    border-radius: 20px;
}

.seccion2-Home{
    background-color: #050309 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    background:url("../../img/fondo.png") ;
    background-repeat: no-repeat;
    height: 693px;
    background-position: right;
    
    
}

.titulo2Home{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
    color: white;
    margin: 32px  32px 0 32px;
}

.rowSeccion2{
    margin-top: 18px;
}

.titulo3Home{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
    color: rgba(234, 54, 55, 1);
    margin-top: 57px;
}

.seccion3-Home{
    background-color: rgba(249, 249, 249, 1);    
}

.seccion4-Home{
    padding-bottom: 50px;
}

.titulo4Home{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
    color: rgba(234, 54, 55, 1);    
    margin-top: 52px;
}
.carouselHome{
    margin-top: 32px;
}
.seccion5-Home{    
    background-color: #F9F9F9;
    padding-bottom: 100px;
}

.titulo5Home{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
    color: rgba(234, 54, 55, 1);    
    padding-top: 52px;
}

@media (max-width: 720px) {
    .titulo1Home{
        font-size: 28px;
    }
    .sub1Home{
        font-size: 16px
    }
    .seccion1-Home{
        margin-top: 24px;            
    }    
    .btnAgendarHome{
        width: 232px;
        height: 44px;
        font-size: 16px;
        margin-top: 25px;
    }

    .titulo2Home{
        font-size: 24px;
        text-align: center;
        margin: 0;
        padding: 32px 10px 0 10px;
    }

    .carouselHome{
        margin-top: 0;
    }
    .seccion2-Home{
        background-color: #050309 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);        
        height: 400px; 
        background: none ;    
        margin-top: 30px;   
    }

    .titulo3Home{        
        font-size: 24px;        
        margin-top: 32px;
    }
    .titulo4Home{        
        font-size: 24px;           
        margin-top: 32px;
    }
    .titulo5Home{        
        font-size: 24px;            
        padding-top: 32px;
    }

    .seccion5-Home{           
        padding-bottom: 70px;
    }

 }