.pillHome3{
    font-family: 'Nunito';
    font-weight: 600 ;
    font-size: 15px ;
    color: white ;
    background-color: rgb(235, 51, 51) !important ;    
    border-radius: 50px;
    margin-left: 16px;
    width: 99px;    
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}
.home3{   
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 40px;
    width: 465px;    
}
.tituloProyecto{
    background-color: black;
}
.nombreProyecto{
    font-family: 'Nunito';
    font-weight: 700 ;
    font-size: 18px ;
    color: white ;
    padding: 19px 0 19px 0;
}

.contImagen-Home3{    
    height: 300px;
}

@media (max-width: 720px){
    .pillHome3{        
        font-size: 9px ;         
        margin-left: 16px;
        width: 67px;        
    }
    .home3{   
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin-top: 40px;
        width: 264px; 
        height: 214px;   
        margin-top: 24px;
    }
    .contImagen-Home3{    
        height: 150px;
    }
    .nombreProyecto{        
        font-size: 13px;        
        padding: 11px 0 11px 0;
    }
    
}