.home1{
    border-radius: 16px; 
    width: 225px;
    height: 230px;
    background-color: white;
    margin-top: 34px;
    
}
.desc1Home{
    font-family: 'Nunito';
    font-size: 18px;
    margin-top: 20px;
}