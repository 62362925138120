.seccion-Contacto{
    background-color: rgba(249, 249, 249, 1);
}
.seccion1-Contacto{
    background-image:url("../../img/fondo_contacto.png");
    background-repeat: no-repeat; 
    background-size: contain;   
    height: 450px;       
}

.titulo1Contacto{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 50px;
    color: white;    
    padding-top: 100px;
}

.texto-Contacto{
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 18px;
    color: rgba(55, 56, 56, 1);  
    
}

.seccion2-Contacto{
    width: 415px;
    margin-left: 133px;
    margin-top: 100px;
    padding-bottom: 100px;
}

.compContacto{
    position: relative;
    margin-top: -170px ;   
    float: none;
    margin-left: 60px;

}

@media (max-width: 720px){
    .seccion1-Contacto{        
        height: 375px;            
    }
    .titulo1Contacto{        
        font-size: 28px;          
        padding-top: 24px;
    }

    .compContacto{
        position: static;
        margin-top: -200px ;   
        float: none;
        margin-left: 0px;    
    }
    .seccion2-Contacto{
        width: auto;
        margin-left: auto;
        margin-top: 40px;
        padding-bottom: 50px;
    }

    .texto-Contacto{        
        font-size: 16px;
        padding: 0 auto 0 auto;
        text-align: center;        
    }
    .imgContacto1{
        width: 300px;
        height: 270px;
    }
    .texto-Contacto{        
        font-size: 16px;    
        padding:  0 50px  0 50px;   
        text-align: start; 
    }

}