.groupComponentServicio2{
    border-radius: 20px;
    background-color: white;
}
.rowCompServicio2{
    position: relative;
    margin-top: -270px ;   
    float: none;
    margin-right: 0px !important;
    margin-left: 0px !important;
    /* top: -600px; */
    
}

.contSubServ2{
    margin-top: 50px;
}

