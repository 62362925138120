.servicios1{    
    border: 10px none;    
    height: 612px;    
    background-color: rgba(11,11,11,1) ;
    background-image:url("../../img/fondo2.png"), url("../../img/fondo_izquierda.png") ;
    background-repeat: no-repeat;
    background-position-x:-40px, right ;
    background-position-y: -85px, 0px;
}
.titleServicios1{                 
    padding-top: 45px;
    font-family: 'Poppins';    
    font-weight: 700;    
    font-size: 45px;       
    color: #FFFFFF;
}

.subtitleServicios2{  
        
    font-family: 'Nunito';    
    font-weight: 400;
    font-size: 20px;  
    max-width: 587px;
    color: #FFFFFF;
    margin-top: 27px;
}

@media (max-width: 720px) { 
    .titleServicios1{
        font-size: 28px;        
    } 
    .subtitleServicios2{
        font-size: 16px
    }
    .servicios1{
        height: 550px;
    }
 }
