.diseño-body{
    background-color: #F5F5F5;;
}
.titulo1-Diseño{
    font-family: Poppins;
    font-size: 45px;
    font-weight: 700;
    color: rgba(12, 12, 12, 1);
    margin-top: 52px;
}

.contenido1-Diseño{
    margin-top: 32px;
}

.operacion1-Diseño{
    font-size: 60px;
    font-weight: 700;
    color: rgba(235, 22, 54, 1);
}

.seccion1-Diseño{
    padding-bottom: 70px;
}

.seccion2-Diseño{
    background-color: rgba(235, 22, 54, 1);
    padding-bottom: 60px;
}

.titulo2Diseño{
    padding-top: 28px;
    font-family: Poppins;
    font-size: 45px;
    font-weight: 700;
    color: white;
}

.contDiseño2{
    margin-top: 40px;
}

.diseñoComp1{
    width: 247px;
    height: 258px;
    border-radius: 16px;
    background-color: rgba(235, 22, 54, 1);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.textoComp1{
    font-family: Nunito;
    font-size: 24px;
    font-weight: 400;    
    text-align: center;
    color: white;
}

@media (max-width: 720px){
    .titulo1-Diseño{        
        font-size: 24px;
        font-weight: 700;        
        margin-top: 32px;
    }

    .titulo2Diseño{        
        font-size: 24px;        
    }
    .seccion2-Diseño{
        background-color: rgba(235, 22, 54, 1);
        padding-bottom: 20px;
    }
    .contDiseño2{
        margin-top: 20px;
    }
    .diseñoComp1{
        width: 123px;
        height: 128px;
        border-radius: 8px; 
        margin-top: 33px; 
    }

    .textoComp1{
        font-family: Nunito;
        font-size: 12.25px;
        font-weight: 400;    
        text-align: center;
        color: white;
    }
}