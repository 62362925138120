*:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.marketing1{
    width: 280px;
    border-radius: 16px;
    background-color: white;
    height: 456px;
}

.imgPlanMarketing1{
    margin-top: 22px;
}

.planMarketing1{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    margin-top: 9px;
}

.detalleMarketing1{
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 15px;
    color: rgba(55, 55, 55, 1);
    margin-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
    
}

.circleIcon{        
    border-radius: 50%;
    width: 17px;
    height: 17px; 
    background-color: rgba(234, 54, 55, 0.2);    
    float: none;    
    margin-right: 5px ;
}

.bi-check{
    padding-left: 1px;
}

.btnMarketing1{
    font-family: 'Poppins';
    font-weight: 600 ;
    font-size: 18px ;
    color: black ;
    background-color: transparent;        
    border-radius: 50px;    
    border-color: black;
    width: 250px;
    height: 47px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}

.btnMarketing2{
    font-family: 'Poppins';
    font-weight: 600 ;
    font-size: 18px ;
    color: white ;
    background-color: rgba(243, 165, 5, 1);        
    border-radius: 50px;    
    width: 250px;
    height: 47px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
    border-style: none;

}
.listaPlanes{
    height: 230px;
}

blob-btn {
    z-index: 1;
    position: relative;
    padding: 20px 46px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;
  }
  .blob-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #000000;
    border-radius: 30px;
  }
  .blob-btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
  }
  .blob-btn:hover {
    color: #ffffff;
    border-radius: 30px;
  }
  .blob-btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 30px;
  }
  .blob-btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #ffffff;
  }
  .blob-btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url("#goo");
  }
  .blob-btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: #000000;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
  }
  @supports (filter: url("#goo")) {
    .blob-btn__blob {
      transform: translate3d(0, 150%, 0) scale(1.4);
    }
  }
  .blob-btn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
  }
  .blob-btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
  }
  .blob-btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
  }
  .blob-btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
  }
  .blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.7);
  }
  @supports (filter: url("#goo")) {
    .blob-btn:hover .blob-btn__blob {
      transform: translateZ(0) scale(1.4);
    }
  }

  blob-btn1 {
    z-index: 1;
    position: relative;
    padding: 20px 46px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    font-weight: bold;
    background-color: rgba(243, 165, 5, 1);
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;
  }
  .blob-btn1:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(243, 165, 5, 1);
    border-radius: 30px;
  }
  .blob-btn1:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
  }
  .blob-btn1:hover {
    color: rgba(243, 165, 5, 1);
    border-radius: 30px;
  }
  .blob-btn1:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 30px;
  }
  .blob-btn__inner1 {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: rgba(243, 165, 5, 1);
  }
  .blob-btn__blobs1 {
    position: relative;
    display: block;
    height: 100%;
    filter: url("#goo");
  }
  .blob-btn__blob1 {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: white;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
  }
  @supports (filter: url("#goo")) {
    .blob-btn__blob1 {
      transform: translate3d(0, 150%, 0) scale(1.4);
    }
  }
  .blob-btn__blob1:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
  }
  .blob-btn__blob1:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
  }
  .blob-btn__blob1:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
  }
  .blob-btn__blob1:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
  }
  .blob-btn1:hover .blob-btn__blob1 {
    transform: translateZ(0) scale(1.7);
  }
  @supports (filter: url("#goo")) {
    .blob-btn1:hover .blob-btn__blob1 {
      transform: translateZ(0) scale(1.4);
    }
  }


@media (max-width: 720px){
    .imgPlanMarketing1{
        height: 70px;
        width: auto;
    }
    .marketing1{
        margin-top: 28px;
    }
    
}