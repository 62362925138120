.tituloContacto1{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 38px;
    padding-top: 27px;
    color: rgba(237, 46, 68, 1)
}

.contacto1{
    width: 579px;
    border-radius: 16px;
    background-color: white;
    padding-bottom: 30px;
    box-shadow: 0px 0px 17.8505px rgba(0, 0, 0, 0.15);
}

.textoForm{
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 18px;
}

.formContainer{
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
}

.btnContacto1{
    font-family: 'Nunito';
    font-weight: 600 ;
    font-size: 18px ;
    color: white ;
    background-color: rgba(237, 46, 68, 1) ;    
    border-radius: 50px;
    border: none;
    width: 215px;
    height: 50px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}

.btnContacto1:hover{
    background-color:rgb(141, 30, 43) ;    
}

.btnContacto2{
    font-family: 'Poppins';
    font-weight: 600 ;
    font-size: 18px ;
    color: #EA3637 ;
    background-color: white ;    
    border-radius: 50px;
    border-style: solid;
    border-color: #EA3637 ;
    width: 215px;
    height: 50px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}
.btnContacto2:hover{
    color: white ;
    background-color: #EA3637 ;
}


@media (max-width: 720px){
    .contacto1{
        width: auto;        
        margin: 0 20px 0 20px;
    }

    .tituloContacto1{        
        font-size: 24px;
        padding-top: 24px;        
    }

    .textoForm{        
        font-size: 16px;
    }
    .form-control , .form-select{
        font-family: Nunito;
        font-size: 14px;
        font-weight: 400;
    }

    .btnContacto1, .btnContacto2{
        font-size: 16px;
        width: 146px;
        justify-content: space-around;
    }
}